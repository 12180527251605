import DashboardCard from '@ui/features/clubhouse/components/DashboardCard';
import {PublicUser} from '@shared/models/user';
import React from 'react';
import {Text} from 'native-base';
import {useTranslation} from 'react-i18next';

interface Props {
    user?: PublicUser;
}

const DrillsCompletedCard = (props: Props) => {
    const {user} = props;
    const {t} = useTranslation();

    return (
        <DashboardCard>
            <Text color='blacks.700' variant='mediumBody'>
                {t('clubhouse.drillsCompleted')}
            </Text>

            <Text
                variant='h4'
            >
                {user?.sports?.golf?.drillsCompleted ?? '-'}
            </Text>
        </DashboardCard>
    );
};

export default DrillsCompletedCard;
