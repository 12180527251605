import {Box, Column, IStackProps} from 'native-base';
import React from 'react';
import {TouchableOpacity} from 'react-native';

interface DashboardCardProps extends IStackProps {
    onPress?: () => void;}

const DashboardCard = (props: DashboardCardProps) => {
    const {onPress, ...columnProps} = props;

    return (
        <Box flex={1}>
            <TouchableOpacity onPress={onPress} disabled={onPress === undefined}>
                <Column
                    bgColor='white'
                    rounded='xl'
                    p={4}
                    space={3}
                    flex={1}
                    {...columnProps}
                />
            </TouchableOpacity>
        </Box>

    );
};

export default DashboardCard;
